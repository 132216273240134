import React, { useState } from "react";
import { MainContainer } from "../../Styles";
import {
  HeadingSecondary,
  PageHeaderOverlay,
  PageHeaderContainer,
} from "../../HeadingElements";
import {
  FormContainer,
  InputField,
  Label,
  ResultField,
  Button,
  InputWrapper,
  Prefix,
  Suffix,
} from "./AffordabilityStyles";

function AffordabilityCalculator() {
  const [grossIncome, setGrossIncome] = useState(0);
  const [interestRate, setInterestRate] = useState(11);
  const [repaymentTerm, setRepaymentTerm] = useState(20);
  const [maxInstallment, setMaxInstallment] = useState(0);
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);

  const calculate = () => {
    const monthlyInterestRate = interestRate / 100 / 12; // Monthly interest rate
    const numPayments = repaymentTerm * 12; // Total number of payments
    const maxMonthly = grossIncome * 0.3; // Assuming 30% of income is used
    setMaxInstallment(maxMonthly);

    // Corrected loan amount formula
    const loanAmount =
      maxMonthly *
      ((1 - Math.pow(1 + monthlyInterestRate, -numPayments)) /
        monthlyInterestRate);
    setMaxLoanAmount(Math.round(loanAmount));
  };
  const handleGrossIncomeChange = (e) => {
    const value = e.target.value.replace(/^0+(?!\.)/, "");
    setGrossIncome(value);
  };
  return (
    <div>
      <PageHeaderContainer>
        <PageHeaderOverlay />
        <HeadingSecondary fontColor="#fff">
          Affordability Calculator
        </HeadingSecondary>
      </PageHeaderContainer>
      <MainContainer>
        <FormContainer>
          <Label>Gross Monthly Income</Label>
          <InputWrapper>
            <Prefix>N$</Prefix>
            <InputField
              type="number"
              value={grossIncome}
              onChange={handleGrossIncomeChange}
            />
          </InputWrapper>

          <Label>Annual Interest Rate</Label>
          <InputWrapper>
            <InputField
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(Number(e.target.value))}
            />
            <Suffix>%</Suffix>
          </InputWrapper>

          <Label>Repayment Term (Years)</Label>
          <InputField
            type="number"
            value={repaymentTerm}
            onChange={(e) => setRepaymentTerm(Number(e.target.value))}
          />
          <Button onClick={calculate}>Calculate</Button>

          <h3>Results</h3>
          <Label>Approximate Maximum Monthly Installment</Label>
          <ResultField>{`N$ ${maxInstallment}`}</ResultField>
          <Label>Approximate Maximum Loan Amount</Label>
          <ResultField>{`N$ ${maxLoanAmount}`}</ResultField>
        </FormContainer>
      </MainContainer>
    </div>
  );
}

export default AffordabilityCalculator;
