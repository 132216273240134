import styled, { keyframes } from "styled-components";
const coinSpin = keyframes`
  0% { transform: translateY(0) rotateY(0deg); }
  50% { transform: translateY(-10px) rotateY(180deg); }
  100% { transform: translateY(0) rotateY(360deg); }
`;

export const GeneralBackgroundContainer = styled.div`
  position: relative;
  width: 100%;

  overflow: hidden;
  background: linear-gradient(135deg, #303030, #444);

  /* Adding larger and sparser cracks with reduced count */
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle at 20% 40%,
        rgba(255, 223, 100, 0.15),
        transparent 75%
      ),
      radial-gradient(
        circle at 80% 60%,
        rgba(255, 223, 100, 0.12),
        transparent 85%
      ),
      repeating-linear-gradient(
        35deg,
        transparent,
        transparent 40px,
        rgba(255, 223, 100, 0.2) 42px,
        rgba(255, 223, 100, 0.2) 45px
      ),
      repeating-linear-gradient(
        -35deg,
        transparent,
        transparent 35px,
        rgba(255, 223, 100, 0.18) 38px,
        rgba(255, 223, 100, 0.18) 42px
      );
    mix-blend-mode: overlay;
    opacity: 0.25;
    z-index: 1;
    pointer-events: none;
  }

  .coin {
    position: absolute;
    width: 60px;
    height: 60px;
    background: radial-gradient(circle at 30% 30%, #ffcc33, #cc9900);
    border-radius: 50%;
    border: 6px solid #cc9900;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4),
      inset 0 -3px 6px rgba(255, 223, 100, 0.6),
      0px 0px 20px 5px rgba(255, 223, 100, 0.4);
    transform: translateZ(0);
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(135deg, #cc9900, #996600);
      border-radius: 50%;
      top: 4px;
      left: 0;
      z-index: -1;
    }

    animation: ${coinSpin} 9s infinite linear;
    transform-origin: center;
  }

  .coin:nth-child(1) {
    top: 50%;
    right: 18%;
    animation-delay: 0s;
  }
  .coin:nth-child(2) {
    top: 35%;
    right: 35%;
    animation-delay: 1.5s;
  }
`;
export const MainContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (min-width: 1024px) {
    padding: 40px;
    justify-content: space-between;
  }
`;
