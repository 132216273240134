import styled from "styled-components";

export const FormContainer = styled.div`
  width: 600px;
  max-width: 90%; /* Reducing width to fit smaller screens */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const InputField = styled.input`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 6px;
  }

  @media (max-width: 480px) {
    padding: 4px;
  }
`;

export const Label = styled.label`
  font-weight: bold;
  margin-top: 10px;
  display: block;
`;

export const ResultField = styled.div`
  margin: 10px 0;
  padding: 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  border: 1px solid #ccc;

  @media (max-width: 480px) {
    padding: 6px;
  }
`;

export const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 8px;
  }

  @media (max-width: 480px) {
    padding: 6px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const Prefix = styled.span`
  margin-right: 5px;
  font-weight: bold;

  @media (max-width: 480px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

export const Suffix = styled.span`
  margin-left: 5px;
  font-weight: bold;

  @media (max-width: 480px) {
    margin-left: 0;
    margin-top: 5px;
  }
`;
