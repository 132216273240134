import React from "react";
import PropTypes from "prop-types";
import "../../styles/Pagination.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    if (totalPages <= 4) {
      // If total pages <= 4, show all pages
      return [...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          className={currentPage === index + 1 ? "active" : ""}
          onClick={() => onPageChange(index + 1)}
        >
          {index + 1}
        </button>
      ));
    }

    // If total pages > 4
    const pagesToShow = [];
    const maxVisiblePages = 4;

    // Calculate start and end for visible range
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage if near the end of page range
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    // Add initial pages and ellipsis if necessary
    if (startPage > 1) {
      pagesToShow.push(
        <button
          key={1}
          className={currentPage === 1 ? "active" : ""}
          onClick={() => onPageChange(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pagesToShow.push(
          <span key="start-ellipsis" className="dots">
            ...
          </span>
        );
      }
    }

    // Add visible page numbers
    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(
        <button
          key={i}
          className={currentPage === i ? "active" : ""}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>
      );
    }

    // Add ellipsis and last page if necessary
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pagesToShow.push(
          <span key="end-ellipsis" className="dots">
            ...
          </span>
        );
      }
      pagesToShow.push(
        <button
          key={totalPages}
          className={currentPage === totalPages ? "active" : ""}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pagesToShow;
  };

  return (
    <div className="pagination">
      <button onClick={handlePrevious} disabled={currentPage === 1}>
        Previous
      </button>
      <div className="pagination-numbers">{renderPageNumbers()}</div>
      <button onClick={handleNext} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
